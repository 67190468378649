import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { toast } from 'react-toastify';

const getInfo = createAsyncThunk(
  'home/info',
  async (_, { rejectWithValue }) => {
    try {
      const lang = localStorage.getItem('i18nextLng');
      const { data } = await axios.get('info', {
        headers: { 'Content-Language': `${lang}` },
      });
      return data;
    } catch (error) {
      toast.error(`${error.response.data.error.message}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return rejectWithValue(error);
    }
  },
);

const changeLang = createAsyncThunk(
  'home/changeLang',
  async (_, { rejectWithValue }) => {
    try {
      const lang = localStorage.getItem('i18nextLng');
      const { data } = await axios.get('info', {
        headers: { 'Content-Language': `${lang}` },
      });
      return data;
    } catch (error) {
      toast.error(`${error.response.data.error.message}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return rejectWithValue(error);
    }
  },
);

const getData = createAsyncThunk(
  'home/game',
  async (_, { rejectWithValue }) => {
    try {
      const lang = localStorage.getItem('i18nextLng');
      const { data } = await axios.get('game', {
        headers: { 'Content-Language': `${lang}` },
      });
      return data.data;
    } catch (error) {
      toast.error(`${error.response.data.error.message}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return rejectWithValue(error);
    }
  },
);

const changeIsVoting = createAsyncThunk('home/IsVoting', payload => payload);
const changeUserCanVote = createAsyncThunk(
  'home/changeUserCanVote',
  payload => payload,
);

const postVote = createAsyncThunk(
  'home/vote',
  async ({ game_id, player_id, onResolve, onReject }, { rejectWithValue }) => {
    try {
      const lang = localStorage.getItem('i18nextLng');
      const { data } = await axios.post('vote', { game_id, player_id }, {
        headers: { 'Content-Language': `${lang}` },
      });
      onResolve(player_id);
      return data.data;
    } catch (error) {
      toast.error(`${error.response.data.error.message}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      onReject();
      return rejectWithValue(error);
    }
  },
);

const saveVote = createAsyncThunk('home/saveVote', async payload => payload);

const getFavorites = createAsyncThunk(
  'home/favorites',
  async ({ page }, { rejectWithValue }) => {
    try {
      const lang = localStorage.getItem('i18nextLng');
      const { data } = await axios.get('top-players', {
        headers: { 'Content-Language': `${lang}` },
        params: { page },
      });
      return { data, page };
    } catch (error) {
      toast.error(`${error.response.data.error.message}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return rejectWithValue(error);
    }
  },
);

const getVideo = createAsyncThunk(
  'home/video',
  async ({ page }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get('videos', { params: { page } });
      return { data, page };
    } catch (error) {
      toast.error(`${error.response.data.error.message}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return rejectWithValue(error);
    }
  },
);

const getNews = createAsyncThunk(
  'home/news',
  async ({ page, excludeId }, { rejectWithValue }) => {
    try {
      const lang = localStorage.getItem('i18nextLng');
      const { data } = await axios.get('news', {
        headers: { 'Content-Language': `${lang}` },
        params: { page, exclude: excludeId },
      });
      return { data, page };
    } catch (error) {
      toast.error(`${error.response.data.error.message}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return rejectWithValue(error);
    }
  },
);

export default {
  getInfo,
  getData,
  changeIsVoting,
  postVote,
  saveVote,
  getFavorites,
  getVideo,
  getNews,
  changeLang,
  changeUserCanVote,
};
