import { CloseIcon, CloseIconUpd } from 'assets/images';
import s from './style.module.scss';
import { createPortal } from 'react-dom';
import { useEffect } from 'react';
import { Overlay } from 'component';

const modalRoot = document.querySelector('#modal-root');

export const Modal = ({ onClose, children, bgColor = '#ffffff', updIcon, customStyles }) => {
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    document.body.classList.add('scrollStop');
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      document.body.classList.remove('scrollStop');
    };
  }, []);

  const handleKeyDown = e => {
    if (e.code === 'Escape') {
      onClose();
      document.body.classList.remove('scrollStop');
    }
  };

  const handleBackdropClick = e => {
    if (e.currentTarget === e.target) {
      onClose();
      document.body.classList.remove('scrollStop');
    }
  };

  return createPortal(
    <Overlay onClick={handleBackdropClick}>
      <div className={customStyles ? customStyles.wrapper : s.Modal}>
        <button
          type="button"
          className={customStyles ?  customStyles.button :  s.Button}
          onClick={onClose}
          aria-label="Close"
        >
          <img
            src={updIcon ? CloseIconUpd : CloseIcon}
            alt="Close Icon"
            width="36"
            height="36"
            loading="lazy"
          />
        </button>
        <div className={customStyles ? customStyles.content : s.Warper} style={{ backgroundColor: bgColor }}>
          {children}
        </div>
      </div>
    </Overlay>,
    modalRoot,
  );
};
