import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import s from './style.module.scss';

export const LangSwisher = () => {
  const [type, setType] = useState('uk');
  const { i18n } = useTranslation();
  const history = useHistory();
  const { pathname } = useLocation();
  const pathPage = pathname.split('/')[1];
  const { slug, lang } = useParams();

  useEffect(() => {
    const localLang = localStorage.getItem('i18nextLng');
    if (lang === 'en' || localLang === 'en') {
      setType('en');
      i18n.changeLanguage('en');
      switchHistory('en');
    }
  }, []);

  const handelLang = lang => {
    i18n.changeLanguage(lang);
    setType(lang);
    switchHistory(lang);
  };

  const switchHistory = lang => {
    if (lang === 'en') {
      if (slug) {
        history.push(`/${pathPage}/${slug}/${lang}`);
      } else {
        history.push(pathPage && `/${pathPage}` + `/${lang}`);
      }
    } else {
      if (slug) {
        history.push(`/${pathPage}/${slug}/`);
      } else {
        history.push(`/${pathPage}`);
      }
    }
  };

  return (
    <div className={s.wrap}>
      <button
        className={type === 'uk' ? s.active : s.disable}
        onClick={() => handelLang('uk')}
      >
        <span>UA</span>
      </button>
      <button
        className={type === 'en' ? s.active : s.disable}
        onClick={() => handelLang('en')}
      >
        <span>EN</span>
      </button>
    </div>
  );
};
