import s from './style.module.scss';
import { useTranslation } from 'react-i18next';
import { Container } from 'component';

export const Policy = () => {
  const { t } = useTranslation();

  return (
    <main className={s.main}>
      <Container>
        <section>
          <h1 className={s.tittle}>{t('policy_text.tittle')}</h1>
          <p className={s.text}>{t('policy_text.tittle_text')}</p>
          <div className={s.policy_item}>
            <h2 className={s.secondTittle}>{t('policy_text.account')}</h2>
            <p className={s.text}>{t('policy_text.account_text')}</p>
          </div>
          <div className={s.policy_item}>
            <h2 className={s.secondTittle}>{t('policy_text.user')}</h2>
            <p className={s.text}>{t('policy_text.user_text')}</p>
          </div>
          <div className={s.policy_item}>
            <h2 className={s.secondTittle}>{t('policy_text.backups')}</h2>
            <p className={s.text}>{t('policy_text.backups_text')}</p>
          </div>
          <div className={s.policy_item}>
            <h2 className={s.secondTittle}>{t('policy_text.links')}</h2>
            <p className={s.text}>{t('policy_text.links_text')}</p>
          </div>
          <div className={s.policy_item}>
            <h2 className={s.secondTittle}>{t('policy_text.changes')}</h2>
            <p className={s.text}>{t('policy_text.changes_text')}</p>
          </div>
          <div className={s.policy_item}>
            <h2 className={s.secondTittle}>{t('policy_text.acceptance')}</h2>
            <p className={s.text}>{t('policy_text.acceptance_text')}</p>
          </div>
          <div className={s.policy_item}>
            <h2 className={s.secondTittle}>{t('policy_text.contacting')}</h2>
            <p className={s.text}>{t('policy_text.contacting_text')}</p>
          </div>
          <strong className={s.create_data}>{t('policy_text.data')}</strong>
        </section>
      </Container>
    </main>
  );
};
