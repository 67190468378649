import React, { useState } from 'react';
import s from './style.module.scss';
import { LevGold, LevGreen } from 'assets/images';

export const ActionBtn = ({
  type = 'button',
  typeLink = 'linkFooter',
  text,
  refer = '',
  active = false,
  isAuth = false,
  handleChange,
  idPlayer,
  target = '',
  disabled,
}) => {
  const [hover, setHover] = useState(false);
  return (
    <div className={s.Box}>
      <img
        className={
          type === 'link'
            ? typeLink === 'linkFooter'
              ? s.CoverLinkFooter
              : s.CoverLinkMain
            : type === 'button'
            ? s.CoverBtn
            : s.CoverAuthBtn
        }
        src={
          type === 'link'
            ? LevGold
            : active || isAuth || hover
            ? LevGold
            : LevGreen
        }
        alt="Lev"
        loading="lazy"
      />
      {type === 'link' ? (
        <a
          className={typeLink === 'linkFooter' ? s.linkFooter : s.linkHero}
          href={refer}
          target={target}
          onClick={e => {
            if (handleChange) {
              e.preventDefault();
              handleChange();
            }
          }}
        >
          {text}
        </a>
      ) : (
        <button
          // className={isAuth ? s.AuthBtn : s.btn}
          className={[isAuth ? s.AuthBtn : s.btn, active && s.active].join(' ')}
          type={type}
          onMouseEnter={() => {
            setHover(true);
          }}
          onMouseLeave={() => {
            setHover(false);
          }}
          // style={
          //   active
          //     ? { border: `1px solid #C59B5A`, color: '#C59B5A' }
          //     : hover
          //     ? { border: `1px solid #C59B5A`, color: '#C59B5A' }
          //     : { border: `1px solid #16894B`, color: '#16894B' }
          // }
          onClick={() => type === 'button' && handleChange(idPlayer)}
          disabled={!active && disabled}
        >
          {text}
        </button>
      )}
    </div>
  );
};
