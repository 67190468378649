import { ActionBtn } from 'component';
import { Crown, AvailableAwardsIcon } from 'assets/images';
import { PieChart } from 'react-minimal-pie-chart';
import homeSelectors from 'redux/home/home-selectors';
import s from './style.module.scss';
import { useSelector } from 'react-redux';
import { UnderlineBtn } from "component";
import {useEffect, useState} from "react";

export const PlayerCard = ({
  textBtn,
  textUnderlineBtn,
  data,
  handelVoice,
  handleStatisticModal,
  textBtnAdd,
  isIncludeBtn = true,
  isIncludeChart = false,
  statistic,
  awards,
  awardsCount
}) => {
  const IsVoting = useSelector(homeSelectors.getIsVoting);
  const IsCanVote = useSelector(homeSelectors.getUserCanVote);
  const winner = useSelector(homeSelectors.getWinner);
  const [isCanVoteFromStorage, setIsCanVoteFromStorage] = useState(null)
  const [userChoicePlayerId, setUserChoicePlayerId] = useState(null)
  // const lsKey = localStorage.getItem("userCanVote")
  const lsKey = localStorage.getItem("date")
  const lsPlayerId = localStorage.getItem('playerId')

  useEffect(() => {
    if (lsKey) {
      setIsCanVoteFromStorage(false)
    } else {
      setIsCanVoteFromStorage(null)
    }
  }, [lsKey]);

  useEffect(() => {
    if (lsPlayerId) {
      setUserChoicePlayerId(lsPlayerId)
    } else {
      setUserChoicePlayerId(null)
    }
  }, [lsPlayerId]);

  return (
    <div className={s.card}>
      <img
        className={s.cover}
        src={data.thumbnail}
        alt="Player"
        loading="lazy"
      />
      {((isCanVoteFromStorage === null ? !IsCanVote : !isCanVoteFromStorage)
        || isIncludeChart
        || Object.keys(winner).length > 0) && (
        <div className={s.chart}>
          <div>
            <PieChart
              style={{
                width: '100%',
                height: '100%',
              }}
              data={[
                { title: '', value: data.percentage, color: '#16894B' },
                {
                  title: '',
                  value: 100 - data.percentage,
                  color: 'transparent',
                },
              ]}
              animate={true}
              lineWidth={16}
              startAngle={-90}
            />
          </div>
          <p>{data.percentage}%</p>
        </div>
      )}

      <div className={s.counter}>
        <img src={Crown} alt="Crown" loading="lazy" />
        <span>{data.number}</span>
      </div>
      <div className={awards && awardsCount >= 0 ? [s.AwardsNameWrap,s.name].join(' ') : s.name}>
        {awards && awardsCount >= 0 &&
          <div className={s.AwardsSubWrap}>
            <span className={s.AwardsCount}>
              {awardsCount}
            </span>
            <img
              className={s.AwardsIcon}
              src={AvailableAwardsIcon}
              alt=""
            />
          </div>
        }
        <p>{data.name}</p>
      </div>
      { statistic &&
        <UnderlineBtn
          type={'button'}
          data={data}
          onClick={handleStatisticModal}
        >
        <span>
          { textUnderlineBtn }
        </span>
        </UnderlineBtn>
      }
      {isIncludeBtn && (
        <div style={{ ...(data.id === +userChoicePlayerId) && {pointerEvents: 'none'} }} className={s.btnWrap}>
          {IsVoting && (
            <ActionBtn
              type="button"
              text={userChoicePlayerId === null
                ? (data.choice ? textBtnAdd : textBtn)
                : data.id === +userChoicePlayerId ? textBtnAdd : textBtn
            }
              active={userChoicePlayerId === null
                ? data.choice
                : data.id === +userChoicePlayerId
            }
              handleChange={handelVoice}
              idPlayer={data.id}
              disabled={isCanVoteFromStorage === null ? !IsCanVote : !isCanVoteFromStorage}
            />
          )}
        </div>
      )}
    </div>
  );
};
