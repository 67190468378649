import { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import homeOperation from 'redux/home/home-operation';
import { Slider } from 'component';
import { useSelector } from 'react-redux';
import homeSelectors from 'redux/home/home-selectors';
import { useTranslation } from 'react-i18next';

export const News = ({ news = [], version }) => {
  const [page, setPage] = useState(1);
  const [refresh, setRefResh] = useState(true);
  const [lastPage, setLastPage] = useState(1);
  const meta = useSelector(homeSelectors.getRedeMoreMeta);
  const { i18n } = useTranslation();
  const refLang = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    refLang.current = i18n.language;
  }, []);

  useEffect(() => {
    if (meta?.last_page) setLastPage(meta?.last_page);
  }, [meta]);

  useEffect(() => {
    if (refLang.current === i18n.language) return;
    refLang.current = i18n.language;
    setPage(1);
    setRefResh(!refresh);
  }, [i18n.language]);

  const handelNext = e => {
    dispatch(homeOperation.getNews({ page: page + 1 }));
    setPage(page + 1);
  };

  return (
    <>
      {news.length > 0 && (
        <Slider
          type="news"
          data={news}
          onNextPaginate={handelNext}
          limit={3}
          isLastPage={lastPage === page}
          version={version}
          page={page}
          refresh={refresh}
          setRefResh={setRefResh}
        />
      )}
    </>
  );
};
