import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { toast } from 'react-toastify';
import operation from '../auth/auth-operation';

const getProfile = createAsyncThunk(
  'personalCab/getProfile',
  async ({ history }, thunkAPI) => {
    try {
      const lang = localStorage.getItem('i18nextLng');
      const { data } = await axios.get(`profile`, {
        headers: { 'Content-Language': `${lang}` },
      });
      return data.data;
    } catch (error) {
      toast.error(`${error.response.data.error.message}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      if (error.response.status === 401) {
        history.push('/');
        thunkAPI.dispatch(operation.clearToken());
      }
      return thunkAPI.rejectWithValue(error);
    }
  },
);

const deleteProfile = createAsyncThunk(
  'personalCab/deleteProfile',
  async ({ onResolve, onReject }, { rejectWithValue }) => {
    try {
      const lang = localStorage.getItem('i18nextLng');
      const response = await axios.delete(`profile`, {
        headers: { 'Content-Language': `${lang}` },
      });
      onResolve();
      return {};
    } catch (error) {
      onReject(error);
      return rejectWithValue(error);
    }
  },
);

export default {
  getProfile,
  deleteProfile,
};
