import EllipsisText from 'react-ellipsis-text';
import s from './style.module.scss';

export const NewsItem = ({ data, version }) => {
  const { photo, title } = data;
  return (
    <>
      <img className={s.coverNew} src={photo} alt={title} />
      <p className={s.titleNew}>
        <EllipsisText text={title} length={version === 'mobile' ? 45 : 60} />
      </p>
    </>
  );
};
