import { BsGlobe } from "react-icons/bs";
import s from './style.module.scss';

export const CalendarMatches = ({ data, children, style }) => {

  const dateFormat = (date) => {
    try {
      // let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date) if add year
      let mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(date)
      let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date)
      let tm = new Intl.DateTimeFormat('en', { hour: '2-digit', minute: '2-digit', hour12: false }).format(date)
      // return {date: `${da}.${mo}.${ye}`, time: `${tm}`}
      return {date: `${da}.${mo}`, time: `${tm}`}
    } catch  {
      return {date: `-`, time: `-`}
    }
  }

  return (
    <div className={s.calWrap}>
      <div className={s.table}>
        {data.map((item)=>
          <div className={s.row} key={item.id}>
            <div className={[s.cell,s.cellTime].join(' ')}>
              <time>{dateFormat(item.start_time).date}</time>
              <time>{dateFormat(item.start_time).time}</time>
            </div>
            <div className={[s.cell,s.cellResult].join(' ')}>
              <div className={s.resultWrap}>
                <div className={[s.left,s.resultItem].join(' ')}>
                  <span className={s.country}>
                    {item.teams.team_1.title}
                  </span>
                  {
                    item.teams.team_1.flag
                      ? <img className={s.countryIcon} src={item.teams.team_1.flag} alt="" loading="lazy"/>
                      :  <BsGlobe
                        className={s.countryIcon}
                        color={'#C59B5A'}
                      />
                  }
                  <span className={s.result}>
                    {item.finished ? item.teams.team_1.score : '-'}
                  </span>
                  :
                </div>
                <div className={[s.right,s.resultItem].join(' ')}>
                  <span className={s.result}>
                    {item.finished ? item.teams.team_2.score : '-'}
                  </span>
                  {
                    item.teams.team_2.flag
                      ? <img className={s.countryIcon} src={item.teams.team_2.flag} alt="" loading="lazy"/>
                      :  <BsGlobe
                        className={s.countryIcon}
                        color={'#C59B5A'}
                      />
                  }
                  <span className={s.country}>{item.teams.team_2.title}</span>
                </div>
              </div>
              <div className={s.countryMob}>
                <span className={s.country}>{item.teams.team_1.title}</span>
                <span className={s.country}>{item.teams.team_2.title}</span>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
