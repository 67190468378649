import { useState, useRef, useEffect } from 'react';
import {
  Modal,
  Input,
  GoogleAuthBtn,
  ActionBtn,
  FacebookAuthBtn,
} from 'component';
import Checkbox from 'react-custom-checkbox';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import operation from 'redux/auth/auth-operation';
import operationData from 'redux/home/home-operation';
import s from './style.module.scss';
import authSelectors from 'redux/auth/auth-selectors';
import homeSelectors from 'redux/home/home-selectors';
import { toast } from 'react-toastify';

export const AuthModal = ({ close, dispatchVote }) => {
  const { t } = useTranslation();
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [error, setError] = useState({});
  const userName = useSelector(authSelectors.getUserName);
  const userEmailGoogl = useSelector(authSelectors.getUserGooglEmail);
  const userEmailFacebook = useSelector(authSelectors.getUserEmailFacebook);
  const playerId = useSelector(homeSelectors.getCurrentVote);
  const dispatch = useDispatch();
  const refNumber = useRef(null);
  const refEmail = useRef(null);
  const patternPhone =
    /\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}/;
  const patternEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
  const [disabledEmail, setDisabledEmail] = useState(false)
  const [disabledPhone, setDisabledPhone] = useState(false)
  const [disabledGoogleAuth, setDisabledGoogleAuth] = useState(false)
  const [disabledFbAuth, setDisabledFbAuth] = useState(false)
  const [isErrPhone, setIsErrPhone] = useState(false)
  const [isErrEmail, setIsErrEmail] = useState(false)

  const handleChange = e => {
    const { name, value } = e.currentTarget;
    switch (name) {
      case 'phone':
        if (!value.includes('+')) {
          setPhone(`+${value}`);
        } else {
          setPhone(value);
        }
        if (error.phone?.length) {
          if (value.length >= 12) {
            setIsErrPhone(true)
          } else {
            setIsErrPhone(false)
          }
        }
        break;
      case 'email':

        if (value.length !== 0) {
          const validEmail = `${value}`.match(patternEmail);
          if (!value.includes('@')) {
            setIsErrEmail(false)
          } else {
            setIsErrEmail(true)
          }

          if (validEmail === null) {
            setIsErrEmail(false)
          } else {
            setIsErrEmail(true)
          }
        }

        setEmail(value);
        break;

      default:
        return;
    }
  };

  useEffect(() => {
    if(phone.length) {
      setDisabledEmail(true)
      setDisabledGoogleAuth(true)
      setDisabledFbAuth(true)
    }
    if(email.length) {
      setDisabledPhone(true)
      setDisabledGoogleAuth(true)
      setDisabledFbAuth(true)
    }
  }, [phone, email])

  const clearAuthType = () => {
    setDisabledPhone(false)
    setDisabledEmail(false)
    setDisabledGoogleAuth(false)
    setDisabledFbAuth(false)
    setPhone('')
    setEmail('')
  }

  const handleGoogAuth = () => {
    setDisabledPhone(true)
    setDisabledEmail(true)
    setDisabledFbAuth(true)
  }

  const handleFbAuth = () => {
    setDisabledPhone(true)
    setDisabledEmail(true)
    setDisabledGoogleAuth(true)
  }

  const handelSubmit = async e => {
    e.preventDefault();

    if (validation()) {
      new Promise((resolve, reject) => {
        dispatch(
          operation.signIn({
            credentials: {
              name: userName,
              phone,
              email:
                userEmailGoogl || userEmailFacebook
                  ? userEmailGoogl
                    ? userEmailGoogl
                    : userEmailFacebook
                  : email,
              checked: isChecked,
            },
            onResolve: resolve,
            onReject: reject,
          }),
        );
      })
        .then(data => {
          dispatch(operationData.getData());
          dispatchVote(playerId);
        })
        .catch(error =>
          toast.error(`${error.response.data.error.message}`, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }),
        )
        .finally(() => {
          close();
        });
    }
  };

  const validation = () => {
    if (
      phone.length === 0 &&
      email.length === 0 &&
      typeof userEmailGoogl !== 'string' &&
      typeof userEmailFacebook !== 'string'
    ) {
      setError({ phone: t('error.isRequired'), email: t('error.isRequired') });
      return false;
    }

    if (phone.length !== 0) {
      const validPhone = `${phone}`.match(patternPhone);
      if (validPhone === null) {
        setError({ phone: t('auth.formatPhone') });
        return false;
      }

      if (validPhone[0].length !== phone.length) {
        setError({ phone: t('auth.formatPhone') });
        return false;
      }
    }
    if (email.length !== 0) {
      const validEmail = `${email}`.match(patternEmail);
      if (!email.includes('@')) {
        setError({ email: t('auth.missing character') });
        return false;
      }

      if (validEmail === null) {
        setError({ email: t('auth.formatEmail') });
        return false;
      }
    }

    if (phone.length > 0 && phone.length < 12) {
      setError({ phone: t('error.phone') });
      return false;
    }

    if (!isChecked) {
      setError({ checked: true });
      return false;
    }

    return true;
  };

  return (
    <Modal onClose={close} updIcon={true}>
      <div className={s.wrap}>
        <strong className={s.tittle}>
          <span>{t('auth.tittle')}</span>
        </strong>
        <form className={s.authForm} onSubmit={handelSubmit}>
          <Input
            disabled={disabledPhone}
            refs={refNumber}
            name={'phone'}
            type={'tel'}
            value={phone}
            handelChange={handleChange}
            placeholder={t('auth.telephone_placeholder')}
            maxLength={14}
            error={error}
            isErr={isErrPhone}
          />
          <div className={s.or}>{t('auth.endText')}</div>
          <Input
            disabled={disabledEmail}
            refs={refEmail}
            name={'email'}
            type={'text'}
            value={email}
            handelChange={handleChange}
            placeholder={t('auth.email_placeholder')}
            maxLength={255}
            error={error}
            isErr={isErrEmail}
          />
          <div className={s.or}>{t('auth.endText')}</div>
          <GoogleAuthBtn
            isDisabledBtn={disabledGoogleAuth}
            callback={handleGoogAuth}
          />
          {/*<div className={[s.or,s.mr].join(' ')}>{t('auth.endText')}</div>*/}
          {/*<FacebookAuthBtn*/}
          {/*  isDisabledBtn={disabledFbAuth}*/}
          {/*  callback={handleFbAuth}*/}
          {/*/>*/}
          { (disabledEmail || disabledPhone) &&
            <button
              onClick={clearAuthType}
              className={s.ResetAuthType}
              type='button'
            >
              {t("auth_change_type_btn")}
            </button>
          }
          <Checkbox
            icon={
              <div
                style={{ background: '#16894B', width: '13px', height: '13px' }}
              >
                {' '}
              </div>
            }
            containerStyle={{
              width: '100%',
              marginTop: '12px'
            }}
            name="my-input"
            checked={isChecked}
            onChange={() => {
              setIsChecked(!isChecked);
            }}
            borderColor={error['checked'] ? '#ff0404' : '#C59B5A'}
            style={{
              cursor: 'pointer',
              width: 27,
              height: 27,
              borderRadius: 0,
            }}
            labelStyle={{
              marginLeft: 12,
              fontFamily: 'Myriad-Pro',
              fontSize: '15px',
              lineHeight: '1.4',
              color: error['checked'] ? '#ff0404' : '#5F5F5F',
              textDecoration: error['checked'] ? 'underline' : 'none',
            }}
            label={t('auth.сoordination')}
          />
          <ActionBtn
            type={'submit'}
            text={t('btn-vote')}
            isAuth={true}
            active={true}
            disabled={
              phone.length === 0 &&
              email.length === 0 &&
              typeof userEmailGoogl !== 'string' &&
              typeof userEmailFacebook !== 'string'
                ? true
                : false
            }
          />
        </form>
      </div>
    </Modal>
  );
};
