import s from './style.module.scss';
export const RuleItem = ({ index, text }) => {
  return (
    <li className={s.ruleItem}>
      <p className={s.line}>
        <span className={s.count}>{`0${index}`}</span>
        <span className={s.text}>{text}</span>
      </p>
    </li>
  );
};
