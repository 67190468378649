import { Footer, Header, Policy } from 'modules';
import { Size } from 'middleware';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

export const PolicyPage = () => {
  const [size, setSize] = useState(0);

  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t('profile.profile_meta')}</title>
      </Helmet>
      <div
        className="adaptiveProfile"
        style={
          size <= 768
            ? {
                transform: `scale(${size / 250})`,
                transformOrigin: 'top left',
              }
            : {
                transform: `scale(${size / 1920})`,
                transformOrigin: 'top left ',
              }
        }
      >
        <Size setSize={setSize}>
          <Header />
          <Policy />
          <Footer isClip={true} />
        </Size>
      </div>
    </>
  );
};
