import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import dataOperation from 'redux/home/home-operation';
import { Slider } from 'component';
import { useSelector } from 'react-redux';
import homeSelectors from 'redux/home/home-selectors';

export const Video = ({ videos = [], version }) => {
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const meta = useSelector(homeSelectors.getVideoMeta);
  const dispatch = useDispatch();

  useEffect(() => {
    if (meta?.last_page) setLastPage(meta?.last_page);
  }, [meta]);

  const handelNext = e => {
    dispatch(dataOperation.getVideo({ page: page + 1 }));
    setPage(page + 1);
  };

  return (
    <>
      {videos.length && (
        <Slider
          type="video"
          data={videos}
          onNextPaginate={handelNext}
          limit={8}
          isLastPage={lastPage === page}
          version={version}
          page={page}
        />
      )}
    </>
  );
};
