import s from './style.module.scss';

export const Input = ({
  refs,
  name,
  type,
  value,
  handelChange,
  placeholder,
  maxLength,
  error,
  disabled,
  isErr
}) => {
  return (
    <label className={s.InputLabel}>
      <span className={s.hideLabel}>{name}</span>
      <input
        className={isErr ? s.hideBorder : ''}
        disabled={disabled}
        ref={refs}
        name={name}
        type={type}
        value={value}
        onChange={handelChange}
        placeholder={placeholder}
        maxLength={maxLength}
        style={
          error[name]
            ? { border: '1px solid red' }
            : { border: '1px solid #C59B5A' }
        }
      />
      {error[name] && <strong className={!isErr ? s.massage : s.hide}>{error[name]}</strong>}
    </label>
  );
};
