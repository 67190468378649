const getIsLoading = state => state.home.isLoading;
const getIsVoting = state => state.home.isVotingActive;
const getPlayer = state => state.home.player;
const statusGames = state => state.home.statusGames;
const getUnixTime = state => state.home.unix;
const getCurrentVote = state => state.home.currentPlayerVote;
const getGameId = state => state.home.game_id;
const getVideo = state => state.home.video;
const getVideoMeta = state => state.home.videoMeta;
const getRedeMore = state => state.home.redeMore;
const getRedeMoreMeta = state => state.home.redeMoreMeta;
const getUserCanVote = state => state.home.userCanVote;
const getReservePlayer = state => state.home.reservePlayer;
const getFavorites = state => state.home.favorites;
const getFavoritesMeta = state => state.home.favoritesMeta;
const getWinner = state => state.home.winner;
const getCalendar = state => state.home.calendar;
const getIsVisibleElement = state => state.home.isVisibleElement

const homeSelectors = {
  getIsLoading,
  getPlayer,
  getReservePlayer,
  statusGames,
  getUnixTime,
  getIsVoting,
  getCurrentVote,
  getGameId,
  getUserCanVote,
  getVideo,
  getRedeMore,
  getRedeMoreMeta,
  getFavorites,
  getVideoMeta,
  getFavoritesMeta,
  getWinner,
  getCalendar,
  getIsVisibleElement
};

export default homeSelectors;
