const getIsLoading = state => state.news.isLoading;
const getDescription = state => state.news.description;
const getId = state => state.news.isId;
const getPhoto = state => state.news.photo;
const getSlug = state => state.news.slug;
const getTitle = state => state.news.title;

const newsSelectors = {
  getIsLoading,
  getDescription,
  getId,
  getPhoto,
  getSlug,
  getTitle,
};

export default newsSelectors;
