import { Container } from 'component';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import homeSelectors from 'redux/home/home-selectors';
import s from './style.module.scss';
import { IsUpdate, Size } from 'middleware';
import { useTranslation } from 'react-i18next';
import newsSelector from 'redux/news/news-selectors';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.core.css';
import 'react-quill/dist/quill.bubble.css';

export const NewsMain = () => {
  const redeMore = useSelector(homeSelectors.getRedeMore);
  const [size, setSize] = useState(0);
  const description = useSelector(newsSelector.getDescription);
  const photo = useSelector(newsSelector.getPhoto);
  const title = useSelector(newsSelector.getTitle);
  const { t } = useTranslation();

  return (
    <main className={s.main}>
      <Size setSize={setSize}>
        <section>
          <Container>
            <img className={s.cover} src={photo} alt={title} />
            <h1 className={s.mainTitle}>{title}</h1>
            <div
              className="ql-editor description"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </Container>
        </section>

        <section className={s.newsSection}>
          <Container>
            <h2 className={s.tittle}>
              <span>{t('read_more_tittle')}</span>
            </h2>

            <IsUpdate
              type={'news'}
              data={redeMore}
              version={size >= 768 ? 'desktop' : 'mobile'}
            />
          </Container>
        </section>
      </Size>
    </main>
  );
};
