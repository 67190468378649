import { createSlice } from '@reduxjs/toolkit';
import operation from './home-operation';

const initialState = {
  isVotingActive: false,
  statusGames: {},
  player: [],
  reservePlayer: [],
  favorites: [],
  favoritesMeta: {}, //TODO optimization
  redeMore: [],
  redeMoreMeta: {}, //TODO optimization
  video: [],
  calendar: [],
  videoMeta: {}, //TODO optimization
  //meta: { favoritesMeta: {}, redeMoreMeta: {}, videoMeta: {} },
  winner: null,
  unix: {},
  currentPlayerVote: null,
  game_id: null,
  isLoading: false,
  userCanVote: false,
  isVisibleElement: true
};

const homeSlice = createSlice({
  name: 'home',
  initialState,
  extraReducers: {
    //====getInfo====//
    [operation.getInfo.pending]: (state, action) => {
      state.isLoading = false;
    },
    [operation.getInfo.fulfilled]: (state, { payload }) => {
      const { data, meta } = payload;
      //===== data======//
      state.statusGames = data.teams;
      state.player = data.players.main;
      state.reservePlayer = data.players.spare;
      state.unix = data.time;
      state.game_id = data.id;
      state.userCanVote = data.user_can_vote;
      state.winner = data.top_player;
      state.video = data.videos;
      state.calendar = data.calendar;
      state.redeMore = data.news;
      state.favorites = data.top_players;
      //===== meta======//
      state.videoMeta = meta.videos;
      state.redeMoreMeta = meta.news;
      state.favoritesMeta = meta.top_players;

      state.isLoading = true;
    },
    [operation.getInfo.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    //====getInfo====//
    [operation.changeLang.fulfilled]: (state, { payload }) => {
      const { data, meta } = payload;
      //===== data======//
      state.statusGames = data.teams;
      state.player = data.players.main;
      state.reservePlayer = data.players.spare;
      state.unix = data.time;
      state.game_id = data.id;
      state.userCanVote = data.user_can_vote;
      state.winner = data.top_player;
      state.video = data.videos;
      state.redeMore = data.news;
      state.favorites = data.top_players;
      state.calendar = data.calendar
      //===== meta======//
      state.videoMeta = meta.videos;
      state.redeMoreMeta = meta.news;
      state.favoritesMeta = meta.top_players;
    },
    //====getData====//
    [operation.getData.fulfilled]: (state, { payload }) => {
      state.statusGames = payload.teams;
      state.player = payload.players.main;
      state.reservePlayer = payload.players.spare;
      state.unix = payload.time;
      state.game_id = payload.id;
      state.userCanVote = payload.user_can_vote;
      state.winner = payload.top_player;
      state.calendar = payload.calendar;
    },
    //====getVideo====//
    [operation.getVideo.fulfilled]: (state, { payload }) => {
      payload.page === 1
        ? (state.video = payload.data.data)
        : (state.video = [...state.video, ...payload.data.data]);
      state.videoMeta = payload.data.meta;
    },

    //====getNews====//
    [operation.getNews.fulfilled]: (state, { payload }) => {
      payload.page === 1
        ? (state.redeMore = payload.data.data)
        : (state.redeMore = [...state.redeMore, ...payload.data.data]);

      state.redeMoreMeta = payload.data.meta;
    },
    //====getFavorites====//
    [operation.getFavorites.fulfilled]: (state, { payload }) => {
      payload.page === 1
        ? (state.favorites = payload.data.data)
        : (state.favorites = [...state.favorites, ...payload.data.data]);

      state.favoritesMeta = payload.data.meta;
    },
    //====changeIsVoting====//
    [operation.changeIsVoting.fulfilled]: (state, { payload }) => {
      state.isVotingActive = payload.isVotingActive;
    },
    //====changeUserCanVote====//
    [operation.changeUserCanVote.fulfilled]: (state, { payload }) => {
      state.userCanVote = payload.userCanVote;
    },
    //====saveVote====//
    [operation.saveVote.fulfilled]: (state, { payload }) => {
      state.currentPlayerVote = payload.idPlayer;
    },
    //====postVote====//
    [operation.postVote.fulfilled]: (state, { payload }) => {
      state.player = payload.main;
      state.reservePlayer = payload.spare;
      state.userCanVote = false;
      // localStorage.setItem("userCanVote", JSON.stringify(false))
      localStorage.setItem("date", new Date().toString())
    },
  },
});
export default homeSlice.reducer;
