import { Container, SponsorLogo, ActionBtn } from 'component';
import {useState, useRef, useEffect} from 'react';
import { Kubok } from 'assets/images';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import homeSelectors from 'redux/home/home-selectors';
import s from './style.module.scss';
import { IsUpdate, Size } from 'middleware';

export const Main = ({ handelVoice, handleStatisticModal }) => {
  const player = useSelector(homeSelectors.getPlayer);
  const reserve = useSelector(homeSelectors.getReservePlayer);
  const favorites = useSelector(homeSelectors.getFavorites);
  const video = useSelector(homeSelectors.getVideo);
  const calendar = useSelector(homeSelectors.getCalendar);
  const redeMore = useSelector(homeSelectors.getRedeMore);
  const winner = useSelector(homeSelectors.getWinner);
  const isVisibleElement = useSelector(homeSelectors.getIsVisibleElement)
  const [size, setSize] = useState(0);
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  const playerRef = useRef();

  const onMove = e => {
    playerRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  // useEffect(() => {
  //   if (Object.keys(winner).length > 0) {
  //     localStorage.removeItem("userCanVote")
  //   }
  // }, [winner]);

  return (
    <main className={s.homeMain}>
      <Size setSize={setSize}>

        <section className={`${s.heroSection} ${Object.keys(winner).length > 0 ? `${s.win}` : ''}`}>
          <Container>
            <div className={s.wrap}>
              <div className={s.content}>
                <SponsorLogo version={size >= 768 ? 'desktop' : 'mobile'} />

                {winner === null || Object.keys(winner).length === 0 ? (
                  <>
                    <h1 className={s.heroTitle}>
                      <span className={s.levelOne}>
                        {t('hero_tittle.level_one')}
                      </span>
                      <span className={s.levelTwo}>
                        {t('hero_tittle.level_two')}
                      </span>
                      <span
                        className={lang === 'uk' ? s.levelTree : s.levelTreeEN}
                      >
                        {t('hero_tittle.level_tree')}
                      </span>
                    </h1>

                    <nav>
                      <ActionBtn
                        type="link"
                        text={t('main_btn_vote')}
                        typeLink={'linkMain'}
                        handleChange={onMove}
                      />
                    </nav>
                  </>
                ) : (
                  <h1 className={s.winnerTitle}>
                    <span className={s.levelTwo}>{t('winner')}</span>
                    <span className={s.winnerName}>{winner.name}</span>
                  </h1>
                )}
              </div>
              {winner === null || Object.keys(winner).length === 0 ? (
                <img
                  className={s.Kubok}
                  src={Kubok}
                  alt="Kubok"
                  loading="lazy"
                />
              ) : (
                <div className={s.crop}>
                  <img
                    className={s.winnerCover}
                    src={winner.photo}
                    alt={winner.name}
                  />
                </div>
              )}
            </div>
          </Container>
        </section>

        <section className={s.mainSquad}>
          <Container style={size <= 767 ? { width: '100%' } : {}}>
            <h2 className={s.tittle} ref={playerRef}>
              <span>{t('squad_command')}</span>
            </h2>
            <ul className={s.playerList}>
              <IsUpdate
                type={'player'}
                data={player}
                t={t}
                handelVoice={handelVoice}
                handleStatisticModal={handleStatisticModal}
                styleClass={s.playerItem}
                conf={{ isMainSquad: true }}
                statistic={true}
              />
            </ul>
          </Container>
        </section>

        <section className={s.spareSquad}>
          <Container style={size <= 767 ? { width: '100%' } : {}}>
            <h2 className={s.tittle}>
              <span className={lang === 'uk' ? s.sm : ''}>
                {t('spare_squad_command')}
              </span>
            </h2>
            <ul className={s.playerList}>
              <IsUpdate
                type={'player'}
                data={reserve}
                t={t}
                handelVoice={handelVoice}
                handleStatisticModal={handleStatisticModal}
                styleClass={s.playerItem}
                conf={{ isMainSquad: false }}
                statistic={true}
              />
            </ul>
          </Container>
        </section>

        <section>
          <Container style={size <= 767 ? { width: '100%' } : {}}>
            <h2 className={s.secondTittle}>
              <span>{t('favorite_tittle')}</span>
            </h2>
            <div className={s.reserveFavorite}>
              <IsUpdate
                type={'favorite'}
                data={favorites}
                handelVoice={handelVoice}
                version={size >= 768 ? 'desktop' : 'mobile'}
                conf={{ size }}
              />
            </div>
          </Container>
        </section>

        { isVisibleElement &&
          <section className={s.bgOrange}>
            <Container style={size <= 767 ? { width: '100%' } : {}}>
              <h2 className={[s.secondTittle,s.tittleDark].join(' ')}>
                <span>{ t('calendar_title') }</span>
              </h2>
              <div className={s.reserveFavorite}>
                <IsUpdate
                  type={'calendar'}
                  data={calendar}
                  version={size >= 768 ? 'desktop' : 'mobile'}
                />
              </div>
            </Container>
          </section>
        }

        <section>
          <Container>
            <h2 className={s.secondTittle}>
              <span>{t('video_tittle')}</span>
            </h2>
            <IsUpdate
              type={'video'}
              data={video}
              version={size >= 768 ? 'desktop' : 'mobile'}
            />
          </Container>
        </section>
        <section>
          <Container>
            <h2 className={s.secondTittle}>
              <span>{t('read_more_tittle')}</span>
            </h2>

            <IsUpdate
              type={'news'}
              data={redeMore}
              version={size >= 768 ? 'desktop' : 'mobile'}
            />
          </Container>
        </section>
      </Size>
    </main>
  );
};
