import { Footer, Header, NewsMain } from 'modules';
import { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import newsOperation from 'redux/news/news-operation';
import operationHome from 'redux/home/home-operation';
import newsSelector from 'redux/news/news-selectors';
import { Helmet } from 'react-helmet-async';
import { Size } from 'middleware';
import { Loader } from 'component';

export const NewsPage = () => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const title = useSelector(newsSelector.getTitle);
  const isLoading = useSelector(newsSelector.getIsLoading);
  const { slug } = useParams();
  const id = slug.match(/[a-z0-9]+$/)[0];
  const [size, setSize] = useState(0);
  const refLang = useRef();

  useEffect(() => {
    dispatch(newsOperation.getNews({ id }));
    dispatch(operationHome.getNews({ page: 1, excludeId: id }));
  }, [slug]);

  useEffect(() => {
    if (refLang.current === i18n.language) return;
    refLang.current = i18n.language;
    dispatch(newsOperation.changeLang({ id }));
    dispatch(operationHome.getNews({ page: 1, excludeId: id }));
  }, [i18n.language]);

  return (
    <>
      {isLoading ? (
        <div
          className="adaptiveOther"
          style={
            size <= 768
              ? {
                  transform: `scale(${size / 250})`,
                  transformOrigin: 'top left',
                }
              : {
                  transform: `scale(${size / 1920})`,
                  transformOrigin: 'top left ',
                }
          }
        >
          <Size setSize={setSize}>
            <Helmet>
              <meta charSet="utf-8" />
              <title>{title}</title>
            </Helmet>
            <Header />
            <NewsMain />
            <Footer isClip={true} />
          </Size>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};
