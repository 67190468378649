import { useMemo, useEffect, useState } from 'react';
import s from './style.module.scss';
import textSize from 'text-size';
import { useSelector } from 'react-redux';
import homeSelectors from 'redux/home/home-selectors';

export const StatusGames = ({ data, type = 'header', version }) => {
  const { team_1, team_2 } = data;
  const [isCorrectSize, setIsCorrectSize] = useState(true);
  const isVisibleElement = useSelector(homeSelectors.getIsVisibleElement)

  const isEmpty = useMemo(() => Object.keys(data).length === 0, [data]);
  useEffect(() => {
    if (type === 'header') return;
    const sizeTeamOne = Math.round(
      textSize.getTextWidth({
        text: team_1.title,
        fontSize: version === 'mobile' ? 12 : 14,
        fontName: 'Myriad-Pro',
      }),
    );
    const sizeTeamTwo = Math.round(
      textSize.getTextWidth({
        text: team_2.title,
        fontSize: 14,
        fontName: 'Myriad-Pro',
      }),
    );
    if (version === 'mobile') {
      setIsCorrectSize(sizeTeamOne <= 60 && sizeTeamTwo <= 60);
    } else {
      setIsCorrectSize(sizeTeamOne <= 80 && sizeTeamTwo <= 80);
    }
  }, [data]);

  return (
    <>
      {!isEmpty &&
        (type === 'header' ? (
          <div className={`${s.wrap} ${!isVisibleElement ? `${s.customHeaderWrap}` : ''}`}>
            <img
              className={s.flagHeader}
              src={team_1.flag}
              alt="Side One Flag"
              loading="lazy"
            />
            <div className={s.wrapInfo}>
              { isVisibleElement &&
                <div className={s.title}>
                  <p>{team_1.title}</p>
                  <span>vs</span>
                  <p>{team_2.title}</p>
                </div>
              }
              <p className={s.status}>{`${team_1.score}:${team_2.score}`}</p>
            </div>
            <img
              className={s.flagHeader}
              src={team_2.flag}
              alt="Side Two Flag"
              loading="lazy"
            />
          </div>
        ) : version === 'mobile' ? (
          <div className={`${s.wrap} ${!isVisibleElement ? `${s.customElementWrap}` : ''}`}>
            <img
              className={s.flagFavorites}
              src={team_1.flag}
              alt="Side One Flag"
              loading="lazy"
            />
            <img
              className={s.flagFavorites}
              src={team_2.flag}
              alt="Side Two Flag"
              loading="lazy"
            />
            <div className={s.wrapInfo}>
              { isVisibleElement &&
                <div
                  className={isCorrectSize ? s.title : s.titleFavoriteCorrectSize}
                >
                  <p>{team_1.title}</p>
                  <span>vs</span>
                  <p>{team_2.title}</p>
                </div>
              }
              <p className={s.status}>{`${team_1.score}:${team_2.score}`}</p>
            </div>
          </div>
        ) : (
          <div className={s.wrapFavorite}>
            <img
              className={s.flagFavorites}
              src={team_1.flag}
              alt="Side One Flag"
              loading="lazy"
            />
            <div className={s.wrapInfoFavorite}>
              { isVisibleElement &&
                <div
                  className={
                    isCorrectSize ? s.titleFavorite : s.titleFavoriteCorrectSize
                  }
                >
                  <p>{team_1.title}</p>
                  <span>vs</span>
                  <p>{team_2.title}</p>
                </div>
              }
              <p className={s.status}>{`${team_1.score}:${team_2.score}`}</p>
            </div>
            <img
              className={s.flagFavorites}
              src={team_2.flag}
              alt="Side Two Flag"
              loading="lazy"
            />
          </div>
        ))}
    </>
  );
};
