import { Loader } from 'component';
import { Footer, Header, Profile } from 'modules';
import { Size } from 'middleware';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import personalCabSelectors from 'redux/personalCab/personalCab-selectors';
import personalCabOperation from 'redux/personalCab/personalCab-operation';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { useHistory } from 'react-router-dom';

export const PersonalCab = () => {
  const [size, setSize] = useState(0);
  const dispatch = useDispatch();
  const isLoading = useSelector(personalCabSelectors.getIsLoading);
  const history = useHistory();
  const { t } = useTranslation();
  useEffect(() => {
    dispatch(personalCabOperation.getProfile({ history }));
  }, []);
  return (
    <>
      {isLoading ? (
        <div
          className="adaptiveProfile"
          style={
            size <= 768
              ? {
                  transform: `scale(${size / 250})`,
                  transformOrigin: 'top left',
                }
              : {
                  transform: `scale(${size / 1920})`,
                  transformOrigin: 'top left ',
                }
          }
        >
          <Size setSize={setSize}>
            <Helmet>
              <meta charSet="utf-8" />
              <title>{t('profile.profile_meta')}</title>
            </Helmet>
            <Header />
            <Profile />
            <Footer isClip={true} />
          </Size>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};
