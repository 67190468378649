import { useEffect } from 'react';
import s from './style.module.scss';
import { GoogleLogin } from 'react-google-login';
import { gapi } from 'gapi-script';
import { useDispatch, useSelector } from 'react-redux';
import operation from 'redux/auth/auth-operation';
import authSelectors from 'redux/auth/auth-selectors';
import { FcCheckmark } from 'react-icons/fc';
import { ColorRing } from 'react-loader-spinner';
import { useTranslation } from 'react-i18next';

export const GoogleAuthBtn = ({ isDisabledBtn, callback }) => {
  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  const userEmail = useSelector(authSelectors.getUserGooglEmail);
  const isLoggedIn = useSelector(authSelectors.getIsLoggedGoogleIn);
  const dispatch = useDispatch();
  useEffect(() => {
    const initClient = () => {
      gapi.client.init({
        clientId: clientId,
        scope: '',
      });
    };
    gapi.load('client:auth2', initClient);
  });

  const onSuccess = res => {
    dispatch(
      operation.googleIn({
        email: res.profileObj.email,
        name: res.profileObj.name,
      }),
    );
  };
  const onFailure = err => {
    console.log('failed:', err);
  };

  const { t } = useTranslation();
  return (
    <div className={s.wrap}>
      <GoogleLogin
        disabled={isDisabledBtn}
        clientId={clientId}
        buttonText=" GOOGLE"
        onSuccess={onSuccess}
        onFailure={onFailure}
        onRequest={callback}
        cookiePolicy={'single_host_origin'}
        render={renderProps => (
          <button
            type="button"
            className={s.googleBtn}
            onClick={renderProps.onClick}
            disabled={renderProps.disabled}
          >
            {t('auth.google')}
          </button>
        )}
        isSignedIn={true}
      />
      {userEmail && <FcCheckmark className={s.FcCheckmark} />}
      {isLoggedIn && (
        <ColorRing
          visible={true}
          className={s.loading}
          height="20"
          width="20"
          ariaLabel="blocks-loading"
          wrapperStyle={{ position: 'absolute', top: '12px', right: '12px' }}
          colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
        />
      )}
    </div>
  );
};
