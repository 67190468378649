import React, { useState } from 'react';
import { Modal } from "../../component";
import { InfoIcon, ReplacementPlayerWithoutNum } from "../../assets/images";
import styl from './style.module.css'

const StatisticModal = ({ close, data }) => {
  const [hintIdx, setHintIdx] = useState(null)

  const showHide = (idx) => {
    setHintIdx((prev) => {
      return prev === idx ? null : idx
    })
  }

  // const getMarkup = () => {
  //   switch (hintIdx) {
  //     case 0:
  //       return (
  //         <div className={styl.Hint}>
  //           Тут має бути пояснення до цього показника case 0
  //           Тут має бути пояснення до цього показника case 0
  //           Тут має бути пояснення до цього показника case 0
  //         </div>
  //       )
  //     case 1:
  //       return (
  //         <div className={styl.Hint}>
  //           Тут має бути пояснення до цього показника case 1
  //         </div>
  //       )
  //     case 2:
  //       return (
  //         <div className={styl.Hint}>
  //           Тут має бути пояснення до цього показника case 2
  //         </div>
  //       )
  //     case 3:
  //       return (
  //         <div className={styl.Hint}>
  //           Тут має бути пояснення до цього показника case 3
  //         </div>
  //       )
  //     default:
  //       break
  //   }
  // }

  return (
    <Modal
      onClose={close}
      updIcon={true}
      customStyles={{
        wrapper: styl.CustomWrapper,
        content: styl.CustomWrapperContent,
        button: styl.Button
      }}
    >
      <div className={styl.Content}>
        <>
          <div className={styl.ContentTopWrap}>
            { data.substitution &&
              <div className={styl.replacementPlayersWrap}>
              <span
                className={[styl.DefaultReplacementNum,styl.TopNun].join(' ')}
              >
                {data.substitution.out.number}
              </span>
                <img
                  className={styl.ReplacementIcon}
                  src={ReplacementPlayerWithoutNum} alt=""
                />
                <span className={[styl.DefaultReplacementNum,styl.BotNum].join(' ')}
                >
                {data.substitution.in.number}
              </span>
              </div>
            }
            <span
              className={styl.MainTitle}
            >
              { data.name }
            </span>
          </div>
          <div className={styl.gridWrap}>
            { data.stats.map(( item, idx) =>
              <div
                className={styl.FlexWrap}
                key={idx}
              >
                <div className={styl.CustomEl}>
                  <span className={styl.CustomElTitle}>
                    {item.value}
                  </span>
                </div>
                <p className={styl.Description}>
                  { item.title }
                </p>
                <div className={styl.HintWrap}>
                  <button
                    className={styl.InfoBtn}
                    onClick={() => showHide(idx)}
                  >
                    <img src={InfoIcon} alt=""/>
                  </button>
                  { idx === hintIdx &&
                    <div className={styl.Hint}>
                      {item.description}
                    </div>
                  }
                </div>
              </div>
            )}
          </div>
        </>
      </div>
    </Modal>
  );
};

export default StatisticModal;