import React from 'react';
import styl from './style.module.css';
export const UnderlineBtn = ({ children, onClick, data, ...props }) => {
  return (
    <button
      className={styl.defButton}
      onClick={() => onClick({data: data, isOpen: true})}
      { ...props }
    >
      { children }
    </button>
  );
};