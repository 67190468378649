import { Container, LangSwisher, StatusGames, Timer } from 'component';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Logo } from 'assets/images';
import homeSelectors from 'redux/home/home-selectors';
import s from './style.module.scss';
import { NavLink, useLocation } from 'react-router-dom';

export const Header = () => {
  const { pathname } = useLocation();
  const statusGames = useSelector(homeSelectors.statusGames);
  const unixTime = useSelector(homeSelectors.getUnixTime);
  const { t } = useTranslation();
  const namePage = pathname.split('/')[1];
  return (
    <header
      className={namePage && namePage !== 'en' ? s.headerNews : s.headerHome}
    >
      <Container>
        <div className={s.wrap}>
          {namePage && namePage !== 'en' && (
            <NavLink to={'/'} className={s.logoLink}>
              <img className={s.logo} src={Logo} alt="logo" loading="lazy" />
            </NavLink>
          )}

          {(!namePage || namePage === 'en') && (
            <Timer getText={t} unix={unixTime} />
          )}
          <LangSwisher />
          {(!namePage || namePage === 'en') && (
            <StatusGames data={statusGames} />
          )}
        </div>
      </Container>
    </header>
  );
};
