import { useMemo, useEffect, useState } from 'react';
import { PlayerCard, News, Video, Favorite, CalendarMatches } from 'component';
import { nanoid } from '@reduxjs/toolkit';
import { isEqual } from 'lodash';

export const IsUpdate = ({
  type,
  data,
  t,
  handelVoice,
  handleStatisticModal,
  styleClass,
  version,
  conf,
  statistic
}) => {
  const [prevState, setPrevState] = useState([]);

  useEffect(() => {
    setPrevState(data);
  }, [data]);

  const renderComponent = useMemo(() => {
    switch (type) {
      case 'player':
        const { isMainSquad } = conf;
        return data.map((el, i) => (
          <li className={styleClass} key={nanoid()}>
            <PlayerCard
              textBtn={t('btn-vote')}
              textBtnAdd={t('btn_vote_add')}
              textUnderlineBtn={t('underline_btn')}
              data={el}
              handelVoice={handelVoice}
              handleStatisticModal={handleStatisticModal}
              isIncludeBtn={isMainSquad}
              statistic={statistic}
            />
          </li>
        ));
      case 'video':
        return <Video videos={data} version={version} />;
      case 'news':
        return <News news={data} version={version} />;
      case 'favorite':
        return <Favorite data={data} version={version} />;
      case 'calendar':
        return <CalendarMatches data={data} version={version} />;
      default:
        break;
    }
  }, [isEqual(data, prevState), version]);
  return renderComponent;
};
