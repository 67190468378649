import { PlayerCard, StatusGames, Paginate } from 'component';
import { useState, useEffect, useRef } from 'react';
import s from './style.module.scss';
import { nanoid } from '@reduxjs/toolkit';
import homeSelectors from 'redux/home/home-selectors';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import dataOperation from 'redux/home/home-operation';
import { useTranslation } from 'react-i18next';

export const Favorite = ({ data, version }) => {
  const [index, setIndex] = useState(0);
  const [viewData, setViewData] = useState([]);
  const [userPage, setUserPage] = useState(1);
  const dispatch = useDispatch();
  const meta = useSelector(homeSelectors.getFavoritesMeta);
  const { i18n } = useTranslation();
  const limit = 6;
  const refLang = useRef();

  useEffect(() => {
    if (viewData.length === 0) {
      setViewData([...data]);
    } else {
      setViewData([...data].slice(index, data.length));
    }
  }, [data]);

  useEffect(() => {
    refLang.current = i18n.language;
  }, []);

  useEffect(() => {
    if (refLang.current === i18n.language) return;
    refLang.current = i18n.language;
    setIndex(0);
    setUserPage(1);
  }, [i18n.language]);

  const handelBack = () => {
    setViewData([...data].slice(index - limit, limit * (userPage - 1)));
    setIndex(index - 6);
    setUserPage(userPage - 1);
  };

  const handelNext = () => {
    if (meta.page === userPage) {
      onNextPaginate();
    } else {
      setViewData([...data].slice(index + limit, limit * (userPage + 1)));
    }
    setUserPage(userPage + 1);
    setIndex(index + limit);
  };

  const onNextPaginate = () => {
    dispatch(dataOperation.getFavorites({ page: meta?.page + 1 }));
  };

  return (
    <Paginate
      back={handelBack}
      next={handelNext}
      isPaginate={true}
      isBack={!(index === 0)}
      isNext={!(meta?.last_page === userPage && meta?.page === userPage)}
    >
      <ul className={s.favoritesList}>
        {viewData.map(el => (
          <li className={s.favoritesItem} key={nanoid()}>
            <StatusGames
              data={el.game.teams}
              type={'favorite'}
              version={version}
            />
            <PlayerCard
              data={el.player}
              isIncludeBtn={false}
              isIncludeChart={true}
              awards={true}
              awardsCount={el.player?.awards}
            />
          </li>
        ))}
      </ul>
    </Paginate>
  );
};
